import React, { createContext, ReactNode, startTransition, useCallback, useContext, useEffect, useState } from 'react';
import defaultConfig from '@crema/constants/defaultConfig';
import PropTypes from 'prop-types';

export type LayoutData = {
  layoutType: string;
  navStyle: string;
  footerType: string;
  headerType: string;
  footer: boolean;
  sidebarCollapsed: boolean;
};

export type LayoutActions = {
  updateLayoutType: (layout: string) => void;
  updateNavStyle: (navStyle: string) => void;
  setFooterType: (footerType: string) => void;
  setFooter: (footer: boolean) => void;
  setHeaderType: (headerType: string) => void;
  setSidebarCollapsed: (value: boolean) => void;
};

const LayoutContext = createContext<LayoutData>({
  footer: defaultConfig.footer,
  navStyle: defaultConfig.navStyle,
  layoutType: defaultConfig.layoutType,
  footerType: defaultConfig.footerType,
  headerType: defaultConfig.headerType,
  sidebarCollapsed: false,
});

const LayoutActionsContext = createContext<LayoutActions>({
  updateLayoutType: () => {},
  updateNavStyle: () => {},
  setFooterType: () => {},
  setFooter: () => {},
  setHeaderType: () => {},
  setSidebarCollapsed: () => {},
});

export const useLayoutContext = () => useContext(LayoutContext);

export const useLayoutActionsContext = () => useContext(LayoutActionsContext);

type LayoutContextProviderProps = {
  children: ReactNode;
};

const LayoutContextProvider: React.FC<LayoutContextProviderProps> = ({ children }) => {
  const [layoutType, updateLayoutType] = useState<string>(defaultConfig.layoutType);
  const [navStyle, setNavStyle] = useState<string>(defaultConfig.navStyle);
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false);

  const updateNavStyle = useCallback((navStyle: string) => {
    setNavStyle(navStyle);
  }, []);

  const [footerType, setFooterType] = useState<string>(defaultConfig.footerType);
  const [footer, setFooter] = useState<boolean>(defaultConfig.footer);
  const [headerType, setHeaderType] = useState<string>(defaultConfig.headerType);

  useEffect(() => {
    //console.log('localStorage should be available, check for menu-open');

    if (typeof localStorage === 'object' && localStorage?.getItem('menu-open') === 'off') {
      startTransition(() => {
        setSidebarCollapsed(true);
      });
    }
  }, []);

  const setSidebarCollapsedX = (value: boolean) => {
    if (typeof localStorage === 'object') {
      localStorage?.setItem('menu-open', value ? 'off' : 'on');
    }

    setSidebarCollapsed(value);
  };

  return (
    <LayoutContext.Provider
      value={{
        navStyle,
        footerType,
        footer,
        layoutType,
        headerType,
        sidebarCollapsed,
      }}
    >
      <LayoutActionsContext.Provider
        value={{
          setFooter,
          setFooterType,
          updateNavStyle,
          updateLayoutType,
          setHeaderType,
          setSidebarCollapsed: setSidebarCollapsedX,
        }}
      >
        {children}
      </LayoutActionsContext.Provider>
    </LayoutContext.Provider>
  );
};

export default LayoutContextProvider;

// LayoutContextProvider.propTypes = {
//   children: PropTypes.node.isRequired,
// };
