export enum ThemeStyleRadius {
  MODERN = 30,
  STANDARD = 16,
}

export enum ThemeMode {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum NavStyle {
  DEFAULT = 'default',
  BIT_BUCKET = 'bit_bucket',
  STANDARD = 'standard',
  MINI_SIDEBAR_TOGGLE = 'mini-sidebar-toggle',
  HEADER_USER_MINI = 'user-mini-header',
  PLAIN = 'plain',
}

export enum FooterType {
  FIXED = 'fixed',
  FLUID = 'fluid',
}

export enum HeaderType {
  FLUID = 'fluid',
  FIXED = 'fixed',
}

export enum Fonts {
  LIGHT = '300',
  REGULAR = '400',
  MEDIUM = '500',
  SEMI_BOLD = '600',
  BOLD = '700',
}

export enum AuthType {
  JWT_AUTH = 'jwt_auth',
}
export enum MessageType {
  MEDIA = 1,
  TEXT = 2,
}

export enum RoutePermittedRole {
  Admin = 'admin',
  User = 'user',
}
